import React from "react";
import Layout from "../../components/layout";
import designStudioHeader from "../../assets/images/service-design-studio-header.svg";
import designStudio from "../../assets/images/service-design-studio.svg";
import { Row, Col } from "react-bootstrap";
import NewNavbar from "../../components/navbar";

class BlogDetailPage extends React.Component {
  render() {
    return (
      <Layout pageInfo={{ pageName: "design-studio" }}>
        <NewNavbar />
        <div className="page-container">
          <Row className="mt100">
            <Col
              className={"inline-pages-content-box service-detail"}
              md={{ span: 12 }}
            >
              <Row className="d-flex align-items-center">
                <Col md={6}>
                  <p className="title">Risklick Design studio</p>
                  <p>
                    Our Design Studio supports the study team with a
                    step-by-step data-driven approach to efficiently designing
                    and optimizing a clinical trial protocol. Risklick combines
                    state-of-the-art technology and expert knowledge, providing
                    everything needed to develop a high-quality protocol. The
                    Design Studio proposes interactive sessions to provide the
                    optimal historical data using Risklick-AI and construct an
                    optimized trial protocol from the start.
                  </p>
                </Col>
                <Col
                  md={6}
                  className="d-flex align-items-center justify-content-center"
                >
                  <img src={designStudioHeader} className="header-img" />
                </Col>
              </Row>
{/* 
              <Row>
                <Col className="text-center">
                  <p className="mb-0">
                    The early phase of a trial protocol production is complex
                    and requires a large quantity of information. The data are
                    usually gathered semi-manually from numerous sources,
                    requiring major efforts and a substantial amount of time to
                    interpret the evidence. Risklick Design Studio has been
                    developed to address clinical trial protocol development
                    from the earliest steps, following a quality by design
                    approach. Starting from the concept sheet or any equivalent
                    early trial document, Risklick-AI provides the optimal set
                    of variables adapted to the specificities of the condition
                    and intervention of the given trial using our unique
                    data-driven approach.
                  </p>
                  <img src={designStudio} className="mt50 mb50" />
                  <p>
                    With the help of key opinion leaders and specialists in
                    trial protocol production and writing, the Risklick Design
                    Studio proposes interactive sessions to provide data and
                    construct an optimal trial protocol from the start. The
                    Design Studio is therefore a unique alternative to utilizing
                    state-of-the-art, data-driven technologies while benefiting
                    from expert input from the earliest step of the trial
                    protocol redaction. With this service, Risklick Design
                    Studio offers the first of its kind, hand-to-hand trial
                    protocol delivery system while following the highest
                    standards of protocol redaction.
                  </p>
                </Col>
              </Row> */}
            </Col>
          </Row>
        </div>
      </Layout>
    );
  }
}

export default BlogDetailPage;
